const cookie_key = 'use-cookie'
const cookie_value = 'yes'
const cookie_disclaimer = document.querySelector('.cookie-disclaimer') as HTMLDivElement

// fetch cookie and close at once
const cookie_item = localStorage.getItem(cookie_key)
if (cookie_item === cookie_value) {
  cookie_disclaimer.style.display = 'none'
}

// click accept-cookie then set accept value
document.querySelectorAll('.accept-cookie').forEach(element => {
  element.addEventListener('click', _ => {
    localStorage.setItem(cookie_key, cookie_value)
    cookie_disclaimer.style.display = 'none'
  })
})
